<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1>新規登録</h1>
                <p class="text-muted">使用者の情報を入力してください。</p>
                <div class="input-box">
                  <div class="small-input">
                    <div>名前：性</div>
                    <input v-model="clientUserData.lastName" class="" type="text" name="name" />
                  </div>
                  <div class="small-input">
                    <div>名</div>
                    <input v-model="clientUserData.firstName" class="" type="text" />
                  </div>
                </div>
                <div class="input-box">
                  <div class="small-input">
                    <div>ふりがな：せい</div>
                    <input v-model="clientUserData.lastNameRuby" class="" type="text" />
                  </div>
                  <div class="small-input">
                    <div>めい</div>
                    <input v-model="clientUserData.firstNameRuby" class="" type="text" />
                  </div>
                </div>
                <div class="input-box">
                  <div class="">
                    <div>性別</div>
                    <input v-model="clientUserData.sex" name="sex" id="male" type="radio" value="男性" />
                    <label for="male">男性</label>
                    <input v-model="clientUserData.sex" name="sex" id="female" type="radio" value="女性" />
                    <label for="female">女性</label>
                    <input v-model="clientUserData.sex" name="sex" id="queer" type="radio" value="どちらでもない" />
                    <label for="queer">どちらでもない</label>
                  </div>
                </div>
                <div class="input-box">
                  <div class="">
                    <div>認証コード</div>
                    <input v-model="clientUserData.authenticationCode" class="middle-input" type="text" name="" />
                  </div>
                  <div>{{ errorMessage }}</div>
                </div>
                <CButton @click="register" color="success" block>登録する</CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import firebase from "firebase";
const db = firebase.firestore();

export default {
  name: "ClientUserRegister",
  data() {
    return {
      clientUserData: {
        uid: this.$store.state.authUser.uid,
        lastName: "",
        lastNameRuby: "",
        firstName: "",
        firstNameRuby: "",
        sex: "",
        role: "",
        authenticationCode: "",
      },
      errorMessage: "",
    };
  },
  methods: {
    register() {
      db.collection("clients")
        .where("authenticationCode", "==", this.clientUserData.authenticationCode)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            db.collection("clientUsers")
              .doc(this.clientUserData.uid)
              .set({
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                clientUserData: this.clientUserData,
                clientData: {
                  // authenticationCode: doc.data().authenticationCode,
                  clientID: doc.data().clientID,
                  companyName: doc.data().clientData.companyName,
                },
              });
            this.$router.push("/dashboard");
          });
        })
        .catch((error) => {
          this.errorMessage = "招待コードが間違っています。";
          // console.log("Error getting documents: ", error);
        });
    },
  },
};
</script>

<style scoped>
.name {
  display: flex;
}

input {
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #768192;
}
input::placeholder {
  color: #bbb;
}

input[type="radio"] {
  margin: 5px;
}

label {
  margin-right: 2rem;
  margin-bottom: 0px;
}

.small-input {
  margin-right: 1rem;
}

.input-box {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-end;
}

.middle-input {
  width: 300px;
}
.course select {
  width: 300px;
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #3c4b64;
}
</style>
